import Navbar from "../Navbar/Navbar"
import './Program.css'
export default function Program()
{
    return(
        <div className="maindiv">
            <Navbar/>
            <br/>
            <div className="flex_container">
                    <div><h4>P2Q4 Symposium Program</h4></div>
                    <div><h4>Oct 14, 2022</h4></div>
                    <div class="flex-links">
                        <a href="https://www.p2q4.org/">https://www.p2q4.org/</a>
                        <a href="https://cmu.zoom.us/j/94924923595?pwd=RFZaVWRXbVN1T2wxNk9WeHhKK01KZz09">Join via zoom</a>
                        <a href="https://drive.google.com/file/d/1R8LF6OOXwAKrJTtQYzgXRxFzjte3KhjE/view?usp=sharing">Speaker Bios</a>
                    </div>
            <div className="table-responsive-sm">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>0900</td>
                            <td>0945</td>
                            <td>GHC 4405<br/>
                                Gates and Hillman Centers</td>
                            <td>Introduction DARPA and Creation of SUS Program (Chair: Raj Reddy) <br/>
                            <a href="https://drive.google.com/file/d/1slYt01rDjg5XB3O0AbJzI599rPpzg2cN/view?usp=share_link">Steve Crocker</a>,
                            <a href="https://drive.google.com/file/d/17_LqHNBSwd6Sxk6PkPWLsC7g5cVQuW4e/view?usp=share_link">&nbsp; Allen Sears</a></td>
                        </tr>
                        <tr>
                            <td>0945</td>
                            <td>1015</td>
                            <td>GHC 4405<br/>
                                Gates and Hillman Centers</td>
                            <td>Coffee Break</td>
                        </tr>
                        <tr>
                            <td>1015</td>
                            <td>1200</td>
                            <td>GHC 4405<br/>
                                Gates and Hillman Centers</td>
                            <td>Recollections of Early Pioneers: 1970s (Chair: Reddy)<br/>
                             <a href="https://drive.google.com/file/d/1zg3edTv9fvz5-m85GPB4A-R2XKl9KCfM/view?usp=share_link">Lee Erman</a>, 
                             <a href="https://drive.google.com/file/d/1pW8LngGr5R5kPhVqPmW49xvoXqTgHPW5/view?usp=share_link">&nbsp; Victor Lesser</a>, 
                             <a href="https://drive.google.com/file/d/1xJYq_cXgEdwtfu8ptH2f4ek4Lizf7a7i/view?usp=share_link">&nbsp; Rick Roth</a>, 
                             <a href="https://drive.google.com/file/d/1qSikLKXfWH6w0PXTkeelI8UMR0-Izenz/view?usp=share_link">&nbsp; Jim Baker</a>, 
                             <a href="https://drive.google.com/file/d/1ifPXqVWNtszdg1K9AdlMQESCTEtB3psz/view?usp=share_link">&nbsp; Janet Baker</a>, 
                             <a href="https://drive.google.com/file/d/143oS7DbBnX2C96wK7aKKgoGTIduH4ySZ/view?usp=share_link">&nbsp; Mark Fox</a>, 
                             <a href="https://drive.google.com/file/d/1rG6_k6CSKOn4bfRPhyA8O45hYSR9l1OI/view?usp=share_link">&nbsp; Jack Mostow</a>, 
                             <a href="https://drive.google.com/file/d/1NcFd7uP7RB2i_cl_5Td1Cwbft7uqMLZP/view?usp=share_link">&nbsp; Fil Alleva</a></td>
                        </tr>

                        <tr>
                            <td>1200</td>
                            <td>1300</td>
                            <td>GHC 4405<br/>
                                Gates and Hillman Centers</td>
                            <td>Lunch</td>
                        </tr>

                        <tr>
                            <td>1300</td>
                            <td>1430</td>
                            <td>GHC 4405<br/>
                                Gates and Hillman Centers</td>
                            <td>Recollections of CMU Speech Group: 1980s (Chair: Richard Stern)<br/>
                            <a href="https://drive.google.com/file/d/1u6QZIMOO5Tycep8kMW80pc0GuI_Kc6pM/view?usp=share_link">Richard Stern</a>,  
                             <a href="https://drive.google.com/file/d/1-eABNeS1NWz40ea0UD-s3rZDcCzxM5eL/view?usp=share_link">&nbsp; Ronald Cole</a>,  
                             <a href="https://drive.google.com/file/d/1mfRDqcbFbrV1v4cizbu1-fckGaAZQytm/view?usp=share_link">&nbsp; Hsiao-Wuen Hon</a>,  
                             <a href="https://drive.google.com/file/d/12eHtQDOZ0_ButgVuvR3TGc63456gF3mC/view?usp=share_link">&nbsp; Wayne Ward</a>,  
                             <a href="https://drive.google.com/file/d/1XkWrTCbINMJbzKl7UpSufFcXfG11TItH/view?usp=share_link">&nbsp; Roni Rosenfeld</a>,  
                             <a href="https://drive.google.com/file/d/18gOPHNefw-EjLrJ2JlqNZIyOAT7x4-sV/view?usp=share_link">&nbsp; Roberto Bisiani</a>,  
                             <a href="https://drive.google.com/file/d/1NU9Cj1eOcQnEoSJHc6FFibn52Ev-fzZQ/view?usp=share_link">&nbsp; Alex Waibel</a>,  
                             <a href="https://drive.google.com/file/d/1JaNSWS0Jog1apHB4UcE-iTjyKug1Ko4I/view?usp=share_link">&nbsp; Alex Acero</a>,  
                             <a href="https://drive.google.com/file/d/1Qtovbmmmpg7wdGTUwP-UJOIKs67QBj3Y/view?usp=share_link">&nbsp; Bhiksha Raj</a></td>
                        </tr>

                        <tr>
                            <td>1430</td>
                            <td>1600</td>
                            <td>GHC 4405<br/>
                                Gates and Hillman Centers</td>
                            <td>Recollections of CMU Speech:1990s and beyond (Chair: Alex Rudnicky)
                                <br/>
                                <a href="https://drive.google.com/file/d/17T8WdePD1hsM8u7pvUsQw64-sJdZPzku/view?usp=share_link">Lin Chase</a>, 
                                <a href="https://drive.google.com/file/d/1_Gdr6FjGLh9fxkqVyIPfOwtR8ZNx7dUi/view?usp=share_link">&nbsp; Florian Metze</a>, 
                                <a href="https://drive.google.com/file/d/1h_bD4F6Jsi2pV13WiUP_-ugJgjgM2BXu/view?usp=share_link">&nbsp; Rita Singh</a>, 
                                <a href="https://drive.google.com/file/d/1olChUWxoWKXCuDvzEHBeeg9YFbadxC2I/view?usp=share_link">&nbsp; Jahanzeb Sherwani</a>, 
                                <a href="https://drive.google.com/file/d/1OZy1I8Ttdoi5X7bFqMtrzHgxs5F7uZTc/view?usp=share_link">&nbsp; Dan Bohus</a>, 
                                <a href="https://drive.google.com/file/d/1n3w2VkILmrpk9chAskvkvdyPdW5uxAKs/view?usp=share_link">&nbsp; Paul Constantinides</a>, 
                                <a href="https://drive.google.com/file/d/14S6AqkmW9BDXCytn0clQIjVnzvrIfUPR/view?usp=share_link">&nbsp; Matt Marge</a>, 
                                <a href="https://drive.google.com/file/d/1P1uN8EVhtAe20EWz9Ml-7lkxtd_NwyFD/view?usp=share_link">&nbsp; Zhou yu</a></td>
                        </tr>

                        <tr>
                            <td>1600</td>
                            <td>1630</td>
                            <td>Rashid Auditorium<br/>
                                Gates and Hillman Centers</td>
                            <td>Move to Rashid Auditorium and Coffee Break</td>
                        </tr>

                        <tr>
                            <td>1630</td>
                            <td>1830</td>
                            <td>Rashid Auditorium<br/>
                                Gates and Hillman Centers</td>
                                <td>Raj Reddy AI Lecture and Panel Discussion<br/>
                                Impact of CMU Speech Group (Chair: Harry Shum and Hsiao Wuen Hon)
                                <br/><a href="https://drive.google.com/file/d/1uDtLcGElp-fUN2TuDLefgxr9YqZD05_s/view?usp=share_link">Janet Baker</a>, 
                                <a href="https://drive.google.com/file/d/1MBp8jjutHPxuTRgxjODElmXRvV8IY9pw/view?usp=share_link">&nbsp; Jim Baker</a>,
                                <a href="https://drive.google.com/file/d/18yncPuuLEtvWaxcQwhq0BfUuyAQoJpjj/view?usp=share_link">&nbsp; KaiFu Lee by Zoom</a>, 
                                <a href="https://drive.google.com/file/d/1dIZ_LkjryYiLmiRLIjgBJxua4x4VQrhj/view?usp=share_link">&nbsp; Alex Waibel</a>, 
                                <a href="https://drive.google.com/file/d/1w1Bd2Atoo8tmBSYAk2inzddPl8t_QQ-I/view?usp=share_link">&nbsp; Xuedong Huang</a>, 
                                <a href="https://drive.google.com/file/d/1M8PuTPbHzyzIPznPFKPfKPOMX74rMEcs/view?usp=sharing">&nbsp; Alex Acero</a></td>
                        </tr>
                        <tr>
                            <td>1830</td>
                            <td>2100</td>
                            <td>Perlis Atrium<br/>
                                Newell Simon Hall</td>
                            <td>Reception and Dinner
                            <br/><a href="https://drive.google.com/file/d/16jDu7ngNMmXMBwKImmFEYtDxs53ujmc9/view?usp=share_link"> Farnam Jahanian</a>, 
                                <a href="https://drive.google.com/file/d/1mm8eGFlUx9Ir0_3XoiEmDcicEEANAh4j/view?usp=share_link">&nbsp; Martial Hebert</a>,
                                <a href="https://drive.google.com/file/d/1mW05UHmwZx8yIrj2tdp0TWVgx-qAzZuM/view?usp=share_link">&nbsp; William Scherlis</a>, 
                                <a href="https://drive.google.com/file/d/1-0evbsDNRCNkhbO0_gmCf3wrIUiuA9nN/view?usp=share_link">&nbsp; Vint Cerf</a>, 
                                <a href="https://drive.google.com/file/d/1NNpbWxpWcajZN61HpVZ3Qiv1MoTds8Xn/view?usp=share_link">&nbsp; Raj Reddy</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}
		





